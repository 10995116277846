@font-face {
  font-family: "RobotoLight";
  src: local("RobotoLight"),
    url("./assets/fonts/RobotoLight.ttf") format("truetype");
  font-weight: 300;
}

body {
  margin: 0;
  font-family: "RobotoLight", source-code-pro, Menlo, Monaco, Consolas;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "RobotoLight ", source-code-pro, Menlo, Monaco, Consolas;
}

::-webkit-scrollbar {
  width: 14px;
}
::-webkit-scrollbar-thumb {
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 9999px;
  background-color: #aaaaaa;
}
