@font-face {
  font-family: "icomoon";
  src: url("./icons/icomoon.eot?ha2r6h");
  src: url("./icons/icomoon.eot?ha2r6h#iefix") format("embedded-opentype"),
    url("./icons/icomoon.ttf?ha2r6h") format("truetype"),
    url("./icons/icomoon.woff?ha2r6h") format("woff"),
    url("./icons/icomoon.svg?ha2r6h#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-google:before {
  content: "\e900";
  color: #fff;
}
.icon-office:before {
  content: "\e901";
  color: #fff;
}
.icon-show:before {
  content: "\e902";
  color: #b8b8b8;
}
.icon-hide:before {
  content: "\e903";
  color: #b8b8b8;
}
.icon-user:before {
  content: "\e904";
  color: #9e9e9e;
}
.icon-error:before {
  content: "\e905";
  color: #e02929;
}
.icon-dashboard:before {
  content: "\e908";
  color: #fff;
}
.icon-profile:before {
  content: "\e909";
  color: #fff;
}
.icon-search:before {
  content: "\e90a";
}
.icon-list:before {
  content: "\e90b";
}
.icon-kanban-view:before {
  content: "\e90c";
}
.icon-globe:before {
  content: "\e90d";
}
.icon-clipboard:before {
  content: "\e90e";
  color: #fff;
}
.icon-calendar:before {
  content: "\e90f";
  color: #fff;
}
.icon-setting:before {
  content: "\e910";
  color: #fff;
}
.icon-question:before {
  content: "\e911";
  color: #fff;
}
.icon-collapse:before {
  content: "\e906";
  color: #b8b8b8;
}
.icon-notification .path1:before {
  content: "\e907";
  color: rgb(158, 158, 158);
}
.icon-notification .path2:before {
  content: "\e912";
  margin-left: -1.02734375em;
  color: rgb(34, 196, 242);
}
.icon-list-top:before {
  content: "\e913";
  color: #fff;
}
.icon-kanban-top:before {
  content: "\e914";
  color: #b8b8b8;
}
.icon-globe-top:before {
  content: "\e915";
  color: #b8b8b8;
}
.icon-arrow-left:before {
  content: "\e916";
  color: #000;
}
.icon-arrow-right:before {
  content: "\e917";
  color: #000;
}
.icon-arrow-down:before {
  content: "\e918";
  color: #9e9e9e;
}
.icon-options:before {
  content: "\e919";
  color: #dbd8d8;
}
.icon-sort:before {
  content: "\e91a";
  color: #b8b8b8;
}
.icon-table-arrow-right:before {
  content: "\e91b";
  color: #22c4f2;
}
.icon-table-arrow-down:before {
  content: "\e91c";
  color: #22c4f2;
}
.icon-close:before {
  content: "\e91d";
  color: #fff;
}
.icon-table-arrow-up:before {
  content: "\e91e";
  color: #22c4f2;
}
.icon-edit:before {
  content: "\e91f";
  color: #fff;
}
.icon-star:before {
  content: "\e920";
  color: #fff;
}
.icon-hold:before {
  content: "\e921";
  color: #fff;
}
.icon-calender:before {
  content: "\e922";
  color: #b8b8b8;
}
.icon-bell:before {
  content: "\e923";
  color: #b8b8b8;
}
.icon-target:before {
  content: "\e924";
  color: #22c4f2;
}
.icon-add-tag .path1:before {
  content: "\e925";
  color: rgb(255, 255, 255);
}
.icon-add-tag .path2:before {
  content: "\e926";
  margin-left: -1em;
  color: rgb(18, 73, 167);
}
.icon-add-tag .path3:before {
  content: "\e927";
  margin-left: -1em;
  color: rgb(18, 73, 167);
}
.icon-chat:before {
  content: "\e928";
  color: #b8b8b8;
}
.icon-check:before {
  content: "\e929";
  color: #00b95d;
}
.icon-header-back-arrow:before {
  content: "\e92a";
  color: #fff;
}
.icon-fill-star:before {
  content: "\e92b";
  color: #ea9d17;
}
.icon-sort-up:before {
  content: "\e92c";
  color: #b8b8b8;
}
.icon-sort-down:before {
  content: "\e92d";
  color: #b8b8b8;
}
.icon-delete:before {
  content: "\e92e";
}
.icon-vmsb:before {
  content: "\e92f";
  color: #fff;
}
.icon-add:before {
  content: "\e930";
  color: #fff;
}
.icon-subscribe:before {
  content: "\e931";
}
.icon-power-off:before {
  content: "\e932";
}
.icon-link:before {
  content: "\e933";
  color: #b8b8b8;
}
.icon-coolicon1:before {
  content: "\e934";
  color: #b8b8b8;
}
.icon-location:before {
  content: "\e935";
  color: #22c4f2;
}
.icon-scorecard:before {
  content: "\e936";
  color: #fff;
}
.icon-message-chat:before {
  content: "\e937";
  color: #fff;
}
.icon-sound-on:before {
  content: "\e938";
  color: #035592;
  cursor: pointer;
}
.icon-sound-off:before {
  content: "\e939";
  color: #b8b8b8;
  cursor: pointer;
}
.icon-note:before {
  content: "\e93a";
  color: #b8b8b8;
}
.icon-microsoft:before {
  content: "\e93b";
  color: #fff;
}
.icon-media-play:before {
  content: "\e93c";
  color: #22c4f2;
}
.icon-child-component:before {
  content: "\e940";
}
.icon-back-to-top-btn:before {
  content: "\e93d";
  color: #22c4f2;
}
.icon-globe-small:before {
  content: "\e93e";
}
.icon-mission:before {
  content: "\e93f";
  color: #fff;
}
.icon-strategy:before {
  content: "\e941";
  color: #fff;
}
.icon-vision:before {
  content: "\e942";
  color: #fff;
}
.icon-benefit:before {
  content: "\e943";
  color: #fff;
}
.icon-on-point:before {
  content: "\e944";
  color: #fff;
}
.icon-crosshair:before {
  content: "\e945";
  color: #fff;
}